import { atom } from 'jotai';

const menuOpenStatus = atom(false);
const backDropStatus = atom(false);
const contactSuccessState = atom(false);
const contactErrorState = atom(false);

export {
  menuOpenStatus,
  backDropStatus,
  contactSuccessState,
  contactErrorState,
};
