import React from 'react';
import { Provider } from 'jotai';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import theme from '@/others/theme';

import Header from '@/components/organisms/common/Header';
import Footer from '@/components/organisms/common/Footer';
import MenuDrawer from '@/components/organisms/common/MenuDrawer';
import FabBox from '@/components/molecules/common/FabBox';

type TopLayooutProps = {
  children: React.ReactNode;
}

const TopLayout: React.VFC<TopLayooutProps> = ({children}) => {
  return (
    <>
      <CssBaseline />
      <Provider>
        <MuiThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <Header />
            <main>
              { children }
            </main>
            <FabBox />
            <Footer />
            <MenuDrawer />
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </Provider>
    </>
  );
};

export default TopLayout;
