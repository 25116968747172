import React from 'react';
import { useAtom } from 'jotai';

import { css, Theme } from '@emotion/react';
import Fab from '@mui/material/Fab';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';

import { menuOpenStatus } from '@/store/store';
import toggleDrawer from '@/others/toggleDrawer';

const menuFabStyles = (theme:Theme) => css({
  background: theme.palette.primaryGradation.default,
  color: '#ffffff',
  border: '1.5px solid #ffffff',
});

const MenuFab: React.VFC = () => {

  const [, setIsOpen] = useAtom(menuOpenStatus);

  return (
    <Fab css={menuFabStyles} onClick={toggleDrawer(true, setIsOpen)}>
      <MenuSharpIcon />
    </Fab>
  );
};

export default MenuFab;
