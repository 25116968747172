import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#46C0EC',
      main: '#2387c7',
      dark: '#004EA2',
    },
    secondary: {
      light: '#F41F1B',
      main: '#be0f0d',
      dark: '#890000',
    },
    success: {
      light: '#46C0EC',
      main: '#2387c7',
      dark: '#004EA2',
    },
    error: {
      light: '#F41F1B',
      main: '#be0f0d',
      dark: '#890000',
    },
    sub: {
      light: '#E3E4E3',
      main: '#a6a7aa',
      dark: '#696B71'
    },
    background: {
      default: '#E3E4E3',
      paper: '#f0f1f0',
    },
    backgroundDark: {
      default: '#696B71',
      paper: '#5d5e64'
    },
    primaryGradation: {
      default: 'linear-gradient(-130deg, #46C0EC, #004EA2)',
    },
    secondaryGradation: {
      default: 'linear-gradient(-140deg, #F41F1B, #890000)',
    },
    subGradation: {
      default: 'linear-gradient(-125deg, #E3E4E3, #696B71)',
    },
  },
  typography: {
    fontFamily: '"Orbitron", "Zen Kaku Gothic New", sans-serif',
    allVariants: {
      color: '#231815',
    },
  }
});

theme.typography.h1 = {
  color: '#ffffff',
  fontFamily: '"Orbitron", "Zen Kaku Gothic New", sans-serif',
  fontWeight: 600,
  fontSize: '1.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.25rem',
  },
};

theme.typography.h2 = {
  fontFamily: '"Orbitron", "Zen Kaku Gothic New", sans-serif',
  fontWeight: 600,
  fontSize: '1.3rem',
  lineHeight: 2,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
};

theme.typography.h3 = {
  fontFamily: '"Orbitron", "Zen Kaku Gothic New", sans-serif',
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: 2,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
};

export default theme;
