import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import ScrollTopFab from '@/components/atoms/common/ScrollTopFab';
import MenuFab from '@/components/atoms/common/MenuFab';

const FabsBoxStyles = css({
  position: 'fixed',
  bottom: 24,
  right: 24,
  zIndex: 500
});

const FabsWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const FabWrapper = css({
  margin: '.2rem',
});

const FabBox: React.VFC = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Fade in={trigger}>
      <Box css={FabsBoxStyles}>
        <Box css={FabsWrapper}>
          <Box css={FabWrapper}>
            <ScrollTopFab />
          </Box>
          <Box css={FabWrapper}>
            <MenuFab />
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default FabBox;
