import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { css, Theme } from '@emotion/react';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';

const scrollToTop = () => {
  scroll.scrollToTop();
};

const scrollTopFabStyles = (theme:Theme) => css({
  background: theme.palette.secondaryGradation.default,
  color: '#ffffff',
  border: '1.5px solid #ffffff',
});

const ScrollTopFab: React.VFC = () => {
  return (
    <Fab css={scrollTopFabStyles} onClick={scrollToTop} size="medium">
      <KeyboardArrowUpSharpIcon />
    </Fab>
  );
};

export default ScrollTopFab;
