import React from 'react';

import { css, Theme } from '@emotion/react';
import Typography from '@mui/material/Typography';

const footerStyles = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default,
});

const footerTextStyles = css({
  color: '#ffffff',
  fontSize: '.8rem',
  padding: '.4rem 0',
});

const Footer: React.VFC = () => {
  return (
    <footer css={footerStyles}>
      <Typography align="center" css={footerTextStyles}>  © {new Date().getFullYear()} 株式会社富士見工務店 all rights reserved.</Typography>
    </footer>
  );
};

export default Footer;
