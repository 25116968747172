import React from 'react';
import { Link } from 'gatsby';
import { useAtom } from 'jotai';

import { css, Theme } from '@emotion/react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';

import LogoHorizontal from '@/svgs/LogoHorizontal.svg';

import { menuOpenStatus } from '@/store/store';
import toggleDrawer from '@/others/toggleDrawer';

const appBarWrapper = css({
  flexGrow: 1,
});

const appBarStyles = (theme: Theme) => css({
  backgroundColor: theme.palette.background.paper,
});

const logoStyles = css({
  height: '30px',
});

const spacer = css({
  flexGrow: 1,
});

const linkStyles = css({
  textDecoration: 'none',
  lineHeight: 1,
});

const iconButtonStyles = (theme: Theme) => css({
  marginLeft: theme.spacing(2),
});

const Header: React.VFC = () => {
  const [, setIsOpen] = useAtom(menuOpenStatus);

  return (
    <Box css={appBarWrapper}>
      <AppBar position="static" css={appBarStyles} elevation={0}>
        <Toolbar>
          <Link to="/" css={linkStyles}>
            <LogoHorizontal css={logoStyles} />
          </Link>
          <div css={spacer} />
          <IconButton
            size="large"
            css={iconButtonStyles}
            onClick={toggleDrawer(true, setIsOpen)}
          >
            <MenuSharpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
